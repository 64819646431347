@if (errorMessage && condition) {
  <div
    [ngClass]="{
      'error-container': type === 'error',
      'success-container': type === 'success',
    }"
  >
    @if (type === 'success') {
      <lucide-icon [img]="CheckCircle2"></lucide-icon>
    } @else {
      <lucide-icon [img]="XCircle"></lucide-icon>
    }

    <span [ngClass]="{ error: type === 'error', success: type === 'success' }">
      {{ errorMessage }}
    </span>
  </div>
}
