import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CheckCircle2, LucideAngularModule, XCircle } from 'lucide-angular';
import { LucideIconData } from 'lucide-angular/icons/types';

@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
  standalone: true,
  imports: [CommonModule, LucideAngularModule],
})
export class InputErrorComponent {
  readonly CheckCircle2: LucideIconData = CheckCircle2;
  readonly XCircle: LucideIconData = XCircle;
  @Input() type: string = 'error';
  @Input() errorMessage: string = '';
  @Input() condition: boolean = true;
}
